// Stile für die Suche
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen

// ***** Allgemeine Suche ******************************************************
.search-query {
	//background: $lightcyan;
	margin: 0 -51px;
	padding: 0px 51px 30px 51px;
	position: relative;

	br, label {
		display: none;
	}
	input.text {
		@extend .form-field;
		@extend .form-field-text;
		border: none;
		display: inline;
		margin-right: 10px;
		width: 575px;
	}
	input[type=submit] {
		position: absolute;
		top: 0;
		right: 51px;
		height: 28px;
		z-index: 2;
		color: transparent;
		background: none;
	}
	&:after {
		position: absolute;
		content: '→';
		font-family: DINWeb-Bold;
		color: #000;
		display: inline-block;
		top: 0px;
		right: 51px;
		display: block;
		height: 28px;
		line-height: 28px;
		z-index: 2;
	}
}

.search-extended {
	display: none;
}

.search-label {
	color: #fff;
}

.search-submit {
	@extend .arrow-small-white-right;
	color: #fff;
	float: none;
}

.search-extended {
	margin: 0 -51px;

	.topicbox-header {
		padding-left: 51px;
		padding-right: 51px;
	}

	.topicbox-content {
		padding: 0 51px;
	}

	.select {
		@extend .form-field;
		@extend .form-field-select;
		display: inline;
		width: 250px;
	}

	.search-param-wm {
		.select {
		}
	}

}

.search-param-wm {
	float: left;
	width: 580px;
}

.search-param-ps {
	float: left;
	width: 270px;
}

// ***** Ergebnisse ************************************************************
.search-result-desc {
	margin: 15px 0;
}

.search-result-header {
	border-top: 1px dotted #c2c2c2;
	padding-top: 20px;

	&:first-child {
		border-top: none;
		padding-top: 0;
	}
}

.search-result-header-link {
	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: underline;
	}
}

.search-result-header-rating {
	@include font-size(18);
	color: $cyan;
	display: block;
	float: right;
	text-align: right;
	width: 10%;
}

.search-result-header-title {
	@include font-size(15);
	display: block;
	float: left;
	width: 90%;
}

// ***** Newssuche *************************************************************
.search-margin-box {
	border: solid black;
	border-width: 0 0 1px 0;
	margin-bottom: 30px;
	padding: 0;
	position: relative;
	input {
		box-shadow: none;
		background: transparent;
		border: 0;
		position: relative;
		display: inline-block;
		z-index: 3;
	}
	input[type=submit] {
		color: transparent;
	}
	&:after {
		position: absolute;
		content: '→';
		font-family: DINWeb-Bold;
		color: #000;
		display: inline-block;
		top: 5px;
		right: 18px;
		z-index: 2;
	}
}

.search-margin-field {
	border: none;
	display: inline;
	margin-right: 10px;
	text-transform: uppercase;
	width: 70%;
}

.search-margin-submit {
	@extend .arrow-small-white-right;
	float: none;
	color: #fff;
}

// ***** Fehlerausgabe *********************************************************
.tx-cyzmnogosearch-pi1 {
	.error {
		margin-top: 20px;
	}
}