// Stile für Veranstaltungen
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.event-date {
	@include font-size(15);
	color: $cyan;
	display: block;
	margin-bottom: 5px;
}

.event-list-item {
	border-top: 1px dotted #c2c2c1;
	padding: 20px 0 0;

	&:first-child {
		border-top: none;
		padding-top: 0;
	}

	img {
		float: left;
		margin: 0 20px 10px 0;
	}

	.url {
		@extend a, .more;
	}
}

.event-list-title {
	@include font-size(18);
	@include line-height(18);
	color: $cyan;
}

.tx-cal-controller {
	.csc-textpic-above {
		margin-top: -15px;
	}
}


// ***** Allgemeines in der rechten Marginalspalte *****************************
.events-margin-header {
	@extend .topicbox-header-events;
	@include font-size(18);
	background-color: #009ee3;
	background-repeat: no-repeat;
	background-position: 338px 10px !important;
	color: #fff;
	font-weight: 700;
	margin: 0;
	padding: 10px 60px 6px;
	text-transform: uppercase;
}

.events-margin-header-dark {
	background-color: #10252e;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAZCAYAAADAHFVeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAT1JREFUeNpi/P//PwMR4BwQizNQBl6yEKlQGojFKLSMhVjL/kDpg0B8gERLooFYBWQGC4katwJxN4l6DKGWMTCRqJGbjOBjgzGYGOgIiLXsH5T+RlEKAeIlQCxMQJ0olM4AYhcsDnkDxDHEWOYHxLxEpkgpIFbEIveFWJ99hloGct0ZHOqcgfgREE9HSyRfgTiHSMcyICf9Y0Dsj0Pda6haZrR4BvEZyUkgbHjUMdIzNTKMWMvwVR2/sCUQYgz8i5TBGaB8UJbgB+LtOPSZIEz4///pfwjYDqrbcODP/7GDH0D8+z9x4BOxPtsIxCJYgl0Fmsl/AvF+Ama8JdZnuHAZVO9jYtRTmkC4SEloA5b0f5Gh/yupVQwMaAFxA4mWOZCimBEYcS+p0HJ6RUxTD+Szp0itJ7LbhMQoAggwAGhk7BNTKYEiAAAAAElFTkSuQmCC) !important;
	background-position: 338px 4px !important;
}



// ***** Monatskalender in der rechten Marginalspalte **************************

.stage-outer .content-area .tx-cal-controller {
	margin-bottom: 50px;
	@include font-size(14);
	color: #000;
	h1, h2 {
		@include font-size(14);
		margin: 0;
		padding: 10px 0;
		font-weight: 700;
	}

	a[class*=events-calendar-nav-link-] {
		font-family: DINWeb-Bold;
		text-indent: 0;
		padding: 10px 0;
		text-decoration: none;
	}
	a.events-calendar-nav-link-prev {
		float: left;
		padding-right: 20px;
	}
	a.events-calendar-nav-link-next {
		float: right;
		padding-left: 20px;
	}
	.events-margin-header {
		border: solid black;
		border-width: 1px 0 0 0;
		background: none;
	}
}
.events-calendar-outer-wrapper {
}

.events-calendar-outer {
	padding: 0;
}

.events-calendar {
	width: 100%;
}

.events-calender-title {
	color: #fff;
	margin: 0;
	padding: 17px 0 3px;
	text-align: center;
	text-transform: uppercase;
}

.events-calender-header {
	font-weight: 700;
	padding: 13px 0;
}

.events-calender-day {
	padding: 13px 0;
	text-align: center;

	&:first-child {
		border-left: none;
	}

	&.monthToday {
		border: 1px solid black;
	}

	&.eventDay {
		font-weight: bold;
		color: #000;
	}


	.url {
		color: #000;
		display: inline-block;
		text-decoration: none;
		width: 100%;
	}
}

.events-calender-nav {
	background: #0096dd;
	margin-bottom: 20px;
	padding: 5px 60px 25px;
}

.events-calendar-nav-link {
	display: block;
	height: 10px;
	width: 13px;

	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}




// ***** Filter in der rechten Marginalspalte **********************************
.events-filter-wrapper {
	color: #000;
	margin-bottom: 20px;
	input[type=submit] {
		border: 0;
		background: #000;
		color: white;
		text-align: center;
		padding: 3px 10px 0;
		line-height: 30px;
		font-weight: 700;
	}
}

.events-filter {
	color: #000;
}

.events-field {
	@include box-sizing;
	border: 0;
	color: #000;
	width: 100%;
}

.events-field-text {
	padding: 4px 7px 2px;
}

.events-field-text-marginleft {
	margin-left: 5px;
	width: 100px;
	width: 10rem;
}

.events-field-text-marginright {
	margin-right: 5px;
	width: 100px;
	width: 10rem;
}

.events-label {
	display: block;
	margin-bottom: 3px;
}

.events-field-select {
	@include box-sizing;
	padding: 0 10px;
	text-transform: uppercase;  /* für Chrome */

	option {
		padding: 2px;

		&:hover {
			background: $cyan;
		}
	}
}

.events-submit {
	@extend .arrow-small-white-right;
	color: #fff;
	float: none;
}

.events-filter-radio-margin {
	display: inline-block;
}
.events-filter-radio-inner-wrapper {
	display: inline-block;
	input:checked + label {
		font-weight: 700;
	}
	&:after {
		content: "/";
		padding: 0 8px;
	}
	&:last-child {
		&:after {
			content: "";
			padding: 0;
		}
	}
}

.events-filter-search-inner-wrapper {
	label,
	input {
		display: inline-block;
		width: auto;
	}
	border: solid #000;
	border-width: 0 0 1px 0;
}
.events-filter-radio-date-wrapper {
	input[type=radio] {
		display: none;
	}
	label {
		display: inline-block;
	}
	display: none;

	.js & {
		display: block;
	}
}

.events-filter-date-inner-wrapper {
	display: inline-block;
	border: solid black;
	border-width: 0 0 1px 0;
	width:47%;
	&:first-child {
		float: left;
	}
	&:last-child {
		float: right;
	}
}

// ***** Frontenderfassung Termine **********************************
.create-event-wrapper {
	margin-bottom: 40px;

	textarea {

	@include border-radius(5px);
	@include box-shadow(2px, 2px, 2px, 0, #cecece, true);
    background: #f2f2f2;
	border: 1px solid #f2f2f2;
	height: 100px;
	height: 10rem;
	@include box-sizing;
	display: block;
	outline: none;  /* für Chrome */
	padding: 5px 5px 3px 5px;
	width: 100%;
	}
}

.form-submit-events {
	@extend .form-submit;
	margin-left: 5px;
	padding-top: 3px;
	&:hover, &:focus {
		text-decoration: underline;
	}
}

.form-submit-back-events {
	@extend .arrow-small-cyan-left;
	float: left;
	margin-left: 5px;
	padding-left: 18px;
	padding-top: 3px;
}
