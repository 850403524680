// Einbindung der Schriften
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen

// ***** DIN Web Regular *******************************************************
@font-face {
  font-family: 'DINWeb';
  src: url('../../Public/Fonts/DINWeb.eot'); /* IE9 Compat Modes */
  src: url('../../Public/Fonts/DINWeb.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../Public/Fonts/DINWeb.woff') format('woff') /* Modern Browsers */
}

// ***** DIN Web Light *********************************************************
@font-face {
  font-family: 'DINWeb-Light';
  src: url('../../Public/Fonts/DINWeb-Light.eot'); /* IE9 Compat Modes */
  src: url('../../Public/Fonts/DINWeb-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../Public/Fonts/DINWeb-Light.woff') format('woff') /* Modern Browsers */
}

// ***** DIN Web Medium ********************************************************
@font-face {
  font-family: 'DINWeb-Medium';
  src: url('../../Public/Fonts/DINWeb-Medium.eot'); /* IE9 Compat Modes */
  src: url('../../Public/Fonts/DINWeb-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../Public/Fonts/DINWeb-Medium.woff') format('woff') /* Modern Browsers */
}

// ***** DIN Web Bold **********************************************************
@font-face {
  font-family: 'DINWeb-Bold';
  src: url('../../Public/Fonts/DINWeb-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../Public/Fonts/DINWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../Public/Fonts/DINWeb-Bold.woff') format('woff') /* Modern Browsers */
}

/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2016 Schwartzco Inc.
        License: 1608-SENFTW
*/


@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../Public/Fonts/AtlasGrotesk-BoldItalic-Web.eot');
  src: url('../../Public/Fonts/AtlasGrotesk-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
  url('../../Public/Fonts/AtlasGrotesk-BoldItalic-Web.woff2') format('woff2'),
  url('../../Public/Fonts/AtlasGrotesk-BoldItalic-Web.woff') format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}

.AtlasGrotesk-BoldItalic-Web {
  font-family: 'Atlas Grotesk Web';
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../Public/Fonts/AtlasGrotesk-Bold-Web.eot');
  src: url('../../Public/Fonts/AtlasGrotesk-Bold-Web.eot?#iefix') format('embedded-opentype'),
  url('../../Public/Fonts/AtlasGrotesk-Bold-Web.woff2') format('woff2'),
  url('../../Public/Fonts/AtlasGrotesk-Bold-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

.AtlasGrotesk-Bold-Web {
  font-family: 'Atlas Grotesk Web';
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../Public/Fonts/AtlasGrotesk-RegularItalic-Web.eot');
  src: url('../../Public/Fonts/AtlasGrotesk-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
  url('../../Public/Fonts/AtlasGrotesk-RegularItalic-Web.woff2') format('woff2'),
  url('../../Public/Fonts/AtlasGrotesk-RegularItalic-Web.woff') format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}

.AtlasGrotesk-RegularItalic-Web {
  font-family: 'Atlas Grotesk Web';
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../Public/Fonts/AtlasGrotesk-Regular-Web.eot');
  src: url('../../Public/Fonts/AtlasGrotesk-Regular-Web.eot?#iefix') format('embedded-opentype'),
  url('../../Public/Fonts/AtlasGrotesk-Regular-Web.woff2') format('woff2'),
  url('../../Public/Fonts/AtlasGrotesk-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

.AtlasGrotesk-Regular-Web {
  font-family: 'Atlas Grotesk Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../Public/Fonts/AtlasGrotesk-Light-Web.eot');
  src: url('../../Public/Fonts/AtlasGrotesk-Light-Web.eot?#iefix') format('embedded-opentype'),
  url('../../Public/Fonts/AtlasGrotesk-Light-Web.woff2') format('woff2'),
  url('../../Public/Fonts/AtlasGrotesk-Light-Web.woff') format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

.AtlasGrotesk-Light-Web {
  font-family: 'Atlas Grotesk Web';
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

