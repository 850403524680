// Stile für die Sitemap
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen

.sitemap {
	padding: 0;
}



// ***** Standardeinstellungen und Hauptnavigation *****************************
.sitemap-level1 {
	@include font-size(18);
	margin: 20px 0 40px;
	>.sitemap-item > .list-item {
		display: block;
		border-bottom: 1px dotted black;
	}
}

.sitemap-level2 {
	margin: 1px 0 20px;
	.sitemap-item {
		line-height: 120%;
	}
}

.sitemap-level3 {
	margin: 0;
}

.sitemap-level4 {
	@include font-size(14);
	border-top: 1px dotted #c2c2c2;
	margin: 0;
	padding: 10px 0;
}

.sitemap-item {
	margin-bottom: 1px;
	line-height: 150%;
}

.sitemap-link {
	display: block;
	padding: 11px 0 6px 0;
	text-decoration: none;

	&:before {
		content: "/";
	}

	&:hover, &:active, &:focus {
		text-decoration: underline;
	}
}



.sitemap-link-level1 {
	color: #000;
}

.sitemap-link-level2 {
	color: #000;
	padding-left: 30px;
}

.sitemap-link-level3 {
	background-position: 90px center;
	padding-left: 60px;
}

.sitemap-link-level3-first {
	border-top: none;
}

.sitemap-link-level4 {
	background-position: 125px center;
	padding: 4px 0 3px 160px;
}



// ***** Anpassungen für die Meta- und Fußnavigation ***************************
.sitemap-link-level1-meta {
}

.sitemap-link-level2-meta {
}

.sitemap-link-level3-meta,
.sitemap-link-level4-meta {
	color: $textColor;
}
