/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}


/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
  left: 10px;
  background: url(../Images/StyleSheets/controls.png) no-repeat 0 -32px;

}

.bx-wrapper .bx-next {
  right: 10px;
  background: url(../Images/StyleSheets/controls.png) no-repeat -43px -32px;
}

.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
