// Stile für Grid Elements
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** Zwei Spalten Aufmacher Startseite *************************************
// wird auch in den Seitentemplates der Kapiteleinstiegsseite und Doorpage verwendet
.twocols-col-left {
	border-right: 1px solid #fff;
	float: left;
	width: 479px;
}

.twocols-col-right {
	float: right;
	width: 480px;
}



// ***** Vier Boxen auf Startseite *********************************************
.home-fourboxes {
	padding: 0;

	.h2 {
		color: $cyan;
		margin-bottom: 15px;
	}

	DIV.frame-type-textpic .frame-type-textpic-imagewrap .frame-type-textpic-image {
		margin-bottom: 10px;
	}
	.home-fourboxes-col {
		.h2 {
			font-weight: 400;
			@include font-size(24);
			color: black;
		}
		p {
			@include font-size(18);
			line-height: 150%;
			color: black;
			a.more {
				font-weight: 400;
				&:before {
					line-height: 150%;
				}
			}
		}
		.frame-type-text,
		.frame-type-textpic {
			border-bottom: 1px solid black;
			padding-bottom: 15px;
			margin-bottom: 31px;
			&:last-child {
				border-width: 0;
			}
		}
	}
}


.four-boxes-header {
	margin: 0;
	padding: 0 0 20px;

}

.home-fourboxes-col {
	float: left;
	box-sizing: border-box;
	width: 50%;

}

.home-fourboxes-col-left {
	padding-right: 21px;
}

.home-fourboxes-col-right {
	padding-left: 21px;
}

.home-fourboxes-col-top {
	.h2 {
		margin-top: 0;
	}
}

.home-fourboxes-col-bottom-inner {

	&:last-child {
		margin-bottom: 0;
	}
}
