// Stile für News
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** Allgemein *************************************************************
.news-date {
	@include font-size(15);
	color: $cyan;
	margin: 10px 0 4px;
}

// ***** Liste *****************************************************************
.news-list-item {
	border-top: 1px dotted #c2c2c1;
	a {
		@extend a, .more;
	}
}

.news-list-item-first {
	border-top: none;
}

.news-list-more {
	a {
		@extend a, .more;
	}
}

.news-list-img {
	float: left;
	margin-right: 20px;
}



// ***** Latest ****************************************************************
.link-list-item-topicbox-ttnews {
	@extend .event-list-item;
	a {
		@extend a, .more;
	}

}

// ***** Detail ****************************************************************
.news-single-img {
	float: left;
	margin-right: 20px;

	img {
		display: block;
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}
	}
}


// Müssen wir leider machen, da in tt_news der Link nicht mit einer Klasse versehbar ist :-(
.link-list-news {
	a {
		@extend .link-list-link;
	}
}
