// Stile für Formulare
//
// @author Lars Beuse <lars@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ******************************************* Structure ***********************

.Tx-Formhandler {
	margin-top: 50px;
}

.form-row {
	margin: 5px 0 30px 0;
  float: left;
  width: 100%;
	label {
		@include font-size(18);
		display: inline-block;
		margin-bottom: 10px;
	}
}

.form-cell {
	float: left;
}

.form-cell-city {
	width: 320px;
}

.form-cell-postalcode {
	margin-right: 30px;
	width: 80px;
}

.form-cell-street {
	margin-right: 30px;
	width: 350px;
}

.form-cell-streetno {
	width: 50px;
}

.form-cell-50-50-first {
	margin-right: 30px;
	width: 271px;
}

.form-cell-50-50-last {
	width: 271px;
}



// ******************************************* Labels ************************

.form-label-checkbox {
	display: inline;
	padding-right: 15px;
}



// ******************************************* Fields ************************

.form-field {
	@include box-sizing;
	display: block;
	outline: none;  /* für Chrome */
	padding: 5px 5px 3px 5px;
	width: 100%;
	@include font-size(18);
}

.form-field-select,
.form-field-text,
.form-field-textarea {
	@include border-radius(5px);
	@include box-shadow(2px, 2px, 2px, 0, #cecece, true);
    background: #f2f2f2;
	border: 1px solid #f2f2f2;
}

.form-field-text {
	width: 100%;
	border: none;
}

.form-field-textarea {
	height: 100px;
	height: 10rem;
}

.form-submit {
	@extend .arrow-small-cyan-right;
	@include font-size(18);
	border: none;
	color: $cyan;
	float: right;
	text-transform: uppercase;
	&:before {
		font-family: DINWeb, Arial, Helvetica, sans-serif;
		@include font-size(18);
		font-weight: bold;
		content: "→";
		color: $cyan;
		margin-right: 7px;
		line-height: 16px;
		vertical-align: top;
	}
}


// ******************************************* Error **************************

.form-field-error {
	border-color: #f00;
}



// ******************************************* Required Marker ****************
.required-marker {
	color: $cyan;
	font-weight: bold;
	@include font-size(16);
}

.required-explanation {
	@include font-size(10);
}



// ***************************************** Freecap **************************
.freecap {
	margin-top: 20px;
}

.freecap-image {
	margin-top: 10px;
	margin-bottom: 10px;
}


// Overwrite bootstrap button
.btn {
	@include font-size(13);
	border: none;
	background-color: transparent;
	color: $cyan;
	font-family: DINWeb-Medium, Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	padding: 0 12px 0 0;
	&:hover, &:focus {
		outline: 0;
	}

	&:hover {
		text-decoration: underline;
	}
}


// Hide powermail form legend
.powermail_fieldset > .powermail_legend {
	display: none;
}
